import { Tag } from '../../../../shared/catalogo/producto/ficha/Tags/Tags'
import { t } from '../../../i18n'
import {
  getNeumaticosEstaciones,
  getNeumaticosReforzadas,
  getNeumaticosTiposVehiculos,
} from '../utils'
import { NeumaticoCocheInfo } from '../types'

export const generateModeloCocheTags = (
  neumaticos: NeumaticoCocheInfo[]
): Tag[] => {
  const tiposVehiculos = getNeumaticosTiposVehiculos(neumaticos)
  const reforzadas = getNeumaticosReforzadas(neumaticos)
  const estaciones = getNeumaticosEstaciones(neumaticos)
  return [
    ...tiposVehiculos.filter(Boolean).map((tipoVehiculo) => ({
      label: tipoVehiculo,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_vehiculo', {
        vehiculo: tipoVehiculo,
      }),
    })),
    ...estaciones.filter(Boolean).map((estacion) => ({
      label: estacion,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_estacion', {
        estacion,
      }),
    })),
    ...reforzadas.filter(Boolean).map((reforzada) => ({
      label: reforzada,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_reforzada', {
        reforzada,
      }),
    })),
  ].filter(Boolean)
}
