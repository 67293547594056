import styles from './accordion.module.scss'
import React, { useMemo, useState } from 'react'
import { groupNeumaticos } from '../utils'
import AccordionHeader from './AccordionHeader'
import Accordion from './Accordion'
import { RequestTyreData } from '../../../types/Neumatico'
import { t } from '../../../i18n'
import RequestTyreCTA from '../Ctas/RequestTyreCTA'
import ComprarNeumaticoAccordionCTA from '../Ctas/ComprarNeumaticoAccordionCTA'
import RequestTyre from '../../RequestTyre/RequestTyre'
import { AccordionContent } from './AccordionContent'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
  NeumaticosCoche,
} from '../types'
import WhiteBoxContainer from '../../../../shared/components/WhiteBoxContainer'
import Size from '../../../utils/media-queries-setup'
import Modal from '../../../../shared/components/Modal/Modal'
import logic from '../../../logic'

const headerItems = [
  {
    title: 'landing_neumaticos.list_header.item_one',
    className: styles.header_title,
  },
  {
    title: 'landing_neumaticos.list_header.item_two',
    className: styles.header_type,
  },
  {
    title: 'landing_neumaticos.list_header.item_three',
    className: styles.header_info,
  },
  {
    title: 'landing_neumaticos.list_header.item_four',
    className: styles.header_button,
  },
]

interface Props {
  modelo: ModeloCocheInfo
  neumaticos: NeumaticosCoche
}

export default function NeumaticosAccordion({ modelo, neumaticos }: Props) {
  const isMobile = Size.useMedia({ mobile: true, tablet: true, desktop: false })

  const [showModal, setShowModal] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreData | null
  )
  const [successMessage, setSuccessMessage] = useState(false)

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const grouped = useMemo(
    () => groupNeumaticos(neumaticos, 'ancho'),
    [neumaticos]
  )

  const doContent = (
    neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
  ) => {
    return (
      <AccordionContent
        key={`accordion_${neumatico.producto.id_navision}`}
        neumatico={neumatico}
        modelo={modelo}
        comprarCta={
          <ComprarNeumaticoAccordionCTA
            neumaticoInfo={neumatico}
            marcaInfo={modelo.marca}
            buttonClass={'accordion_button'}
          />
        }
        requestTyreCta={
          <RequestTyreCTA
            neumatico={neumatico}
            modelo={modelo}
            openModal={setShowModal}
            selectNeumatico={setSelectedNeumatico}
            flexDirection={'row'}
          />
        }
      />
    )
  }

  const filteredHeaderItems = useMemo(() => {
    // Remove la header de Tipo en mobile
    return isMobile
      ? headerItems.filter(
          (item) => item.title !== 'landing_neumaticos.list_header.item_two'
        )
      : headerItems
  }, [isMobile])

  return (
    <React.Fragment>
      <WhiteBoxContainer className="fullWidthContent">
        <Accordion
          grouped={grouped}
          header={<AccordionHeader items={filteredHeaderItems} />}
          contentRenderFnc={doContent}
          itemHeader={'landing_neumaticos.list_item_header.ancho'}
        />
      </WhiteBoxContainer>
      <Modal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
          closeModal={closeModal}
          requestAvailability={({
            email,
            selectedNeumatico,
            device,
            precio,
            idioma,
            cantidad = 1,
          }) =>
            logic.requestNeumaticoAvailability(
              email,
              selectedNeumatico.marca,
              selectedNeumatico.modelo,
              selectedNeumatico.id_site_modelo_neumatico,
              selectedNeumatico.id_site_producto,
              selectedNeumatico.tyreSpecs,
              device,
              precio,
              idioma,
              cantidad
            )
          }
        />
      </Modal>
    </React.Fragment>
  )
}
