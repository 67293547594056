import React, { ReactNode } from 'react'
import { textWithLocale } from '../../../i18n'
import {
  generateFichaNeumaticoUrl,
  getNeumaticoSpecs,
  neumaticoActivo,
} from '../utils'
import styles from './accordion.module.scss'
import {
  ModeloCocheInfo,
  NeumaticoCocheInfo,
  NeumaticoCocheProducto,
} from '../types'
import {
  ConsumoLevels,
  LLuviaLevels,
  RuidoLevels,
} from '../../../types/Ecolabel'
import EcoLabels from '../Info/EcoLabels/EcoLabels'
import Size from '../../../utils/media-queries-setup'

interface ContentProps {
  neumatico: NeumaticoCocheInfo & NeumaticoCocheProducto
  modelo: ModeloCocheInfo
  comprarCta: ReactNode
  requestTyreCta: ReactNode
}

export const AccordionContent = ({
  neumatico,
  modelo,
  comprarCta,
  requestTyreCta,
}: ContentProps) => {
  const isMobile = Size.useMedia({ mobile: true, tablet: true, desktop: false })

  const nombreProducto = `${
    neumatico.producto[textWithLocale('nombre_producto')]
  } ${getNeumaticoSpecs(neumatico)}`
  const nombre =
    neumatico.url_publica === 1 ? (
      <a href={generateFichaNeumaticoUrl(neumatico, modelo)}>
        {nombreProducto}
      </a>
    ) : (
      nombreProducto
    )

  return (
    <ul key={neumatico.id_site_productos_neumaticos}>
      <li>
        <div className={styles.accordion__item_container}>
          <div
            className={`${styles.accordion__item_element} ${styles.item_element_title}`}>
            <h3>{nombre}</h3>
          </div>
          {!isMobile && (
            <div
              className={`${styles.accordion__item_element} ${styles.item_element_type}`}>
              {neumatico.vehiculo || '-'}
            </div>
          )}
          <div
            className={`${styles.accordion__item_element} ${styles.item_element_info}`}>
            <EcoLabels
              consumo={neumatico.consumo as ConsumoLevels}
              lluvia={neumatico.lluvia as LLuviaLevels}
              ruido={neumatico.nivel_ruido as RuidoLevels}
              decibelios={neumatico.decibelios}
              eprel={neumatico.eprel}
              isFullInfo={false}
            />
          </div>
          <div
            className={`${styles.accordion__item_element} ${styles.item_element_button}`}>
            {neumaticoActivo(neumatico.producto) ? comprarCta : requestTyreCta}
          </div>
        </div>
      </li>
    </ul>
  )
}
